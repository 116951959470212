import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Running a gRPC service";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "running-a-grpc-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#running-a-grpc-service",
        "aria-label": "running a grpc service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Running a gRPC service`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#grpcservice"
          }}>{`GrpcService`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#decorating-a-grpcservice"
          }}>{`Decorating a GrpcService`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#grpc-web"
          }}>{`gRPC-Web`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#unframed-requests"
          }}>{`Unframed requests`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#blocking-service-implementation"
          }}>{`Blocking service implementation`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#using-typeblocking-annotation"
            }}>{`Using type://@Blocking annotation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#wrapping-a-service-with-typeservicerequestcontextblockingtaskexecutor"
            }}>{`Wrapping a service with type://ServiceRequestContext#blockingTaskExecutor()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#using-typegrpcservicebuilderuseblockingtaskexecutorboolean"
            }}>{`Using type://GrpcServiceBuilder#useBlockingTaskExecutor(boolean)`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#exception-propagation"
          }}>{`Exception propagation`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#server-reflection"
          }}>{`Server Reflection`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#see-also"
          }}>{`See also`}</a></p>
      </li>
    </ul>
    <Tip mdxType="Tip">
      <p>{`Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria-examples"
        }}>{`armeria-examples`}</a>{` to find a fully working example.`}</p>
    </Tip>
    <p>{`Let's assume we have the following `}<a parentName="p" {...{
        "href": "https://grpc.io/"
      }}>{`gRPC`}</a>{` service definition:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-protobuf"
      }}>{`syntax = "proto3";

package grpc.hello;

option java_package = "com.example.grpc.hello";

service HelloService {
  rpc Hello (HelloRequest) returns (HelloReply) {}
}

message HelloRequest {
  string name = 1;
}

message HelloReply {
  string message = 1;
}
`}</code></pre>
    <p>{`The Protobuf compiler will produce some Java code under the `}<inlineCode parentName="p">{`com.example.grpc.hello`}</inlineCode>{` package.
The most noteworthy one is `}<inlineCode parentName="p">{`HelloServiceGrpc.java`}</inlineCode>{` which defines the base service class we will implement:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class HelloServiceGrpc {
    ...
    public static abstract class HelloServiceImplBase implements BindableService {
        public void hello(HelloRequest req, StreamObserver<HelloReply> responseObserver) {
            asyncUnimplementedUnaryCall(METHOD_HELLO, responseObserver);
        }
        ...
    }
    ...
}
`}</code></pre>
    <p>{`Our implementation would look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyHelloService extends HelloServiceGrpc.HelloServiceImplBase {
    @Override
    public void hello(HelloRequest req, StreamObserver<HelloReply> responseObserver) {
        HelloReply reply = HelloReply.newBuilder()
                                     .setMessage("Hello, " + req.getName() + '!')
                                     .build();
        responseObserver.onNext(reply);
        responseObserver.onCompleted();
    }
}
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`It is recommended to use the build plugin as explained in
`}<a parentName="p" {...{
          "href": "https://github.com/grpc/grpc-java/blob/master/README.md#download"
        }}>{`the gRPC-Java README`}</a>{` rather than
running `}<inlineCode parentName="p">{`protoc`}</inlineCode>{` manually.`}</p>
    </Tip>
    <h2 {...{
      "id": "grpcservice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#grpcservice",
        "aria-label": "grpcservice permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`GrpcService`}</inlineCode></h2>
    <p>{`Once you've finished the implementation of the service, you need to build a `}<a parentName="p" {...{
        "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
      }}>{`type://GrpcService`}</a>{` using
a `}<a parentName="p" {...{
        "href": "type://GrpcServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html"
      }}>{`type://GrpcServiceBuilder`}</a>{` and add it to the `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.ServerBuilder;

ServerBuilder sb = Server.builder();
...
sb.service(GrpcService.builder()
                      .addService(new MyHelloService())
                      .build());
...
Server server = sb.build();
server.start();
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`We bound the `}<a parentName="p" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{` without specifying any path. It is because `}<a parentName="p" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{`
implements `}<a parentName="p" {...{
          "href": "type://HttpServiceWithRoutes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpServiceWithRoutes.html"
        }}>{`type://HttpServiceWithRoutes`}</a>{`, which dynamically provides `}<a parentName="p" {...{
          "href": "typeplural://Route:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Route.html"
        }}>{`typeplural://Route`}</a>{` by itself.`}</p>
    </Tip>
    <Tip mdxType="Tip">
      <p><a parentName="p" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{` supports `}<a parentName="p" {...{
          "href": "https://grpc.io/"
        }}>{`gRPC`}</a>{`, `}<a parentName="p" {...{
          "href": "https://github.com/grpc/grpc/blob/master/doc/PROTOCOL-WEB.md"
        }}>{`gRPC-Web`}</a>{` and `}<a parentName="p" {...{
          "href": "https://developers.google.com/protocol-buffers/docs/proto3#json"
        }}>{`Protobuf-JSON`}</a>{` by default.
If you don't want your `}<a parentName="p" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{` support all those formats, specify the formats you want
using `}<a parentName="p" {...{
          "href": "type://GrpcServiceBuilder#supportedSerializationFormats(Iterable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#supportedSerializationFormats(java.lang.Iterable)"
        }}>{`type://GrpcServiceBuilder#supportedSerializationFormats(Iterable)`}</a>{`.`}</p>
    </Tip>
    <h2 {...{
      "id": "decorating-a-grpcservice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#decorating-a-grpcservice",
        "aria-label": "decorating a grpcservice permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Decorating a `}<inlineCode parentName="h2">{`GrpcService`}</inlineCode></h2>
    <p>{`Unlike a usual Armeria service, `}<a parentName="p" {...{
        "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
      }}>{`type://GrpcService`}</a>{` implements a special interface called
`}<a parentName="p" {...{
        "href": "type://HttpServiceWithRoutes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpServiceWithRoutes.html"
      }}>{`type://HttpServiceWithRoutes`}</a>{`. Therefore, it is recommended to decorate a `}<a parentName="p" {...{
        "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
      }}>{`type://GrpcService`}</a>{` by specifying
decorator functions as extra parameters rather than using `}<inlineCode parentName="p">{`Service.decorate()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.logging.LoggingService;

ServerBuilder sb = Server.builder();
...
sb.service(GrpcService.builder()
                      .addService(new MyHelloService())
                      .addService(new MySecondHelloService())
                      .build(),
           LoggingService.newDecorator());
...
Server server = sb.build();
server.start();
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`See `}<a parentName="p" {...{
          "href": "/docs/server-decorator#decorating-servicewithroutes"
        }}>{`Decorating `}<inlineCode parentName="a">{`ServiceWithRoutes`}</inlineCode></a>{` for more information
about `}<a parentName="p" {...{
          "href": "type://HttpServiceWithRoutes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpServiceWithRoutes.html"
        }}>{`type://HttpServiceWithRoutes`}</a>{`.`}</p>
    </Tip>
    <p>{`The above decorator decorates all services. If you want to decorate only a specific service, you need to specify
the decorators when you add the service to the `}<a parentName="p" {...{
        "href": "type://GrpcServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html"
      }}>{`type://GrpcServiceBuilder`}</a>{`,
or use the `}<a parentName="p" {...{
        "href": "type://@Decorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Decorator.html"
      }}>{`type://@Decorator`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`GrpcService.builder()
           .addService(new MyHelloService(),
                       List.of(new FirstDecorator(), new SecondDecorator())) // 👈👈👈
           .build()

// Or, use the @Decorator directly to the service or method.
@Decorator(ThirdDecorator.class) // 👈👈👈
class MyHelloService extends MyHelloServiceBase {

    @Decorator(FourthDecorator.class) // 👈👈👈
    @Override
    public void sayHello(HelloRequest request, StreamObserver<HelloResponse> responseObserver) {
        ...
    }
}
`}</code></pre>
    <p>{`As you might have noticed already, the decorators will be applied in the order of
`}<inlineCode parentName="p">{`FirstDecorator`}</inlineCode>{`, `}<inlineCode parentName="p">{`SecondDecorator`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThirdDecorator`}</inlineCode>{`, and `}<inlineCode parentName="p">{`FourthDecorator`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "grpc-web",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#grpc-web",
        "aria-label": "grpc web permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`gRPC-Web`}</inlineCode></h2>
    <p><a parentName="p" {...{
        "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
      }}>{`type://GrpcService`}</a>{` supports the `}<a parentName="p" {...{
        "href": "https://github.com/grpc/grpc/blob/master/doc/PROTOCOL-WEB.md"
      }}>{`gRPC-Web`}</a>{` protocol,
a small modification to the `}<a parentName="p" {...{
        "href": "https://grpc.io/"
      }}>{`gRPC`}</a>{` wire format that can be used from a browser.
Use the `}<a parentName="p" {...{
        "href": "https://github.com/grpc/grpc-web"
      }}>{`gRPC-Web-Client`}</a>{` to access the service from a browser.`}</p>
    <Tip mdxType="Tip">
      <p><a parentName="p" {...{
          "href": "https://github.com/grpc/grpc-web"
        }}>{`gRPC-Web-Client`}</a>{` does not support client-side
(or bi-directional) streaming yet but only server-side streaming.
See `}<a parentName="p" {...{
          "href": "https://github.com/grpc/grpc-web/issues/24"
        }}>{`here`}</a>{` for more information.`}</p>
    </Tip>
    <p>{`If the origin of the Javascript and API server are different, `}<a parentName="p" {...{
        "href": "https://github.com/grpc/grpc-web"
      }}>{`gRPC-Web-Client`}</a>{` first sends `}<inlineCode parentName="p">{`preflight`}</inlineCode>{`
requests by the HTTP `}<inlineCode parentName="p">{`OPTIONS`}</inlineCode>{` method, in order to determine whether the actual request is safe to send
in terms of CORS. Armeria provides `}<a parentName="p" {...{
        "href": "type://CorsService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/cors/CorsService.html"
      }}>{`type://CorsService`}</a>{` to handle this requests, so you need to decorate it when
you build a `}<a parentName="p" {...{
        "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
      }}>{`type://GrpcService`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.grpc.protocol.GrpcHeaderNames;
import com.linecorp.armeria.server.cors.CorsService;
import com.linecorp.armeria.server.cors.CorsServiceBuilder;

ServerBuilder sb = Server.builder();
...
final CorsServiceBuilder corsBuilder =
        CorsService.builder("http://foo.com")
                   .allowRequestMethods(HttpMethod.POST) // Allow POST method.
                   // Allow Content-type and X-GRPC-WEB headers.
                   .allowRequestHeaders(HttpHeaderNames.CONTENT_TYPE,
                                        HttpHeaderNames.of("X-GRPC-WEB"))
                   // Expose trailers of the HTTP response to the client.
                   .exposeHeaders(GrpcHeaderNames.GRPC_STATUS,
                                  GrpcHeaderNames.GRPC_MESSAGE,
                                  GrpcHeaderNames.ARMERIA_GRPC_THROWABLEPROTO_BIN);

sb.service(GrpcService.builder()
                      .addService(new MyHelloService())
                      .build(),
           corsBuilder.newDecorator(),
           LoggingService.newDecorator());
...
Server server = sb.build();
server.start();
`}</code></pre>
    <p>{`Please refer to `}<a parentName="p" {...{
        "href": "/docs/server-cors"
      }}>{`Configuring CORS`}</a>{` for more information.`}</p>
    <h2 {...{
      "id": "unframed-requests",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#unframed-requests",
        "aria-label": "unframed requests permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Unframed requests`}</h2>
    <p><a parentName="p" {...{
        "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
      }}>{`type://GrpcService`}</a>{` supports serving unary RPC methods (no streaming request or response) without `}<a parentName="p" {...{
        "href": "https://grpc.io/"
      }}>{`gRPC`}</a>{`
wire format framing. This can be useful for gradually migrating an existing HTTP POST based API to `}<a parentName="p" {...{
        "href": "https://grpc.io/"
      }}>{`gRPC`}</a>{`.
As `}<a parentName="p" {...{
        "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
      }}>{`type://GrpcService`}</a>{` supports both binary protobuf and `}<a parentName="p" {...{
        "href": "https://developers.google.com/protocol-buffers/docs/proto3#json"
      }}>{`Protobuf-JSON`}</a>{`, either legacy protobuf
or JSON APIs can be used.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
...
sb.service(GrpcService.builder()
                      .addService(new MyHelloService())
                      .enableUnframedRequests(true)
                      .build());
...
Server server = sb.build();
server.start();
`}</code></pre>
    <p>{`This service's unary methods can be accessed from any HTTP client at e.g., URL `}<inlineCode parentName="p">{`/grpc.hello.HelloService/Hello`}</inlineCode>{`
with Content-Type `}<inlineCode parentName="p">{`application/protobuf`}</inlineCode>{` for binary protobuf POST body or `}<inlineCode parentName="p">{`application/json; charset=utf-8`}</inlineCode>{`
for JSON POST body.`}</p>
    <h2 {...{
      "id": "blocking-service-implementation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#blocking-service-implementation",
        "aria-label": "blocking service implementation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Blocking service implementation`}</h2>
    <p>{`Unlike upstream gRPC-Java, Armeria does not run service logic in a separate thread pool by default. If your
service implementation requires blocking, run the individual blocking logic in a thread pool,
annotate the gRPC services or methods with a `}<a parentName="p" {...{
        "href": "type://@Blocking:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Blocking.html"
      }}>{`type://@Blocking`}</a>{` annotation, wrap the entire service implementation in
`}<inlineCode parentName="p">{`ServiceRequestContext.current().blockingTaskExecutor().submit()`}</inlineCode>{`, or set
`}<a parentName="p" {...{
        "href": "type://GrpcServiceBuilder#useBlockingTaskExecutor(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#useBlockingTaskExecutor(boolean)"
      }}>{`type://GrpcServiceBuilder#useBlockingTaskExecutor(boolean)`}</a>{` so the above happens automatically for
all service methods and lifecycle callbacks.`}</p>
    <h3 {...{
      "id": "using-typeblocking-annotation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-typeblocking-annotation",
        "aria-label": "using typeblocking annotation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<a parentName="h3" {...{
        "href": "type://@Blocking:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Blocking.html"
      }}>{`type://@Blocking`}</a>{` annotation`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// You can apply the @Blocking annotation at the class level
// so all methods are executed by ServiceRequestContext.current().blockingTaskExecutor().
@Blocking // 👈
class MyBlockingGrpcServiceImpl extends MyGrpcServiceImplBase {

    public void blockingCall1(...) {
        // Perform a long-running task.
        ...
    }

    public void blockingCall2(...) {
        // Perform a long-running task.
        ...
    }
}

class MyGrpcServiceImpl extends MyGrpcServiceImplBase {

    //  The method will be executed by ServiceRequestContext.current().blockingTaskExecutor().
    @Blocking // 👈
    public void blockingCall(...) {
        // Perform a long-running task.
        ...
    }

    public void nonBlockingCall(...) {
        ...
    }
}
`}</code></pre>
    <h3 {...{
      "id": "wrapping-a-service-with-typeservicerequestcontextblockingtaskexecutor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#wrapping-a-service-with-typeservicerequestcontextblockingtaskexecutor",
        "aria-label": "wrapping a service with typeservicerequestcontextblockingtaskexecutor permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wrapping a service with `}<a parentName="h3" {...{
        "href": "type://ServiceRequestContext#blockingTaskExecutor():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#blockingTaskExecutor()"
      }}>{`type://ServiceRequestContext#blockingTaskExecutor()`}</a></h3>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.RequestContext;
import com.linecorp.armeria.server.ServiceRequestContext;

public class MyHelloService extends HelloServiceGrpc.HelloServiceImplBase {
    @Override
    public void hello(HelloRequest req, StreamObserver<HelloReply> responseObserver) {
        ServiceRequestContext.current().blockingTaskExecutor().submit(() -> {
            // Perform a long-running task.

            HelloReply reply = HelloReply.newBuilder()
                                         .setMessage("Hello, " + req.getName() + '!')
                                         .build();
            responseObserver.onNext(reply);
            responseObserver.onCompleted();
        });
    }
}
`}</code></pre>
    <h3 {...{
      "id": "using-typegrpcservicebuilderuseblockingtaskexecutorboolean",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-typegrpcservicebuilderuseblockingtaskexecutorboolean",
        "aria-label": "using typegrpcservicebuilderuseblockingtaskexecutorboolean permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<a parentName="h3" {...{
        "href": "type://GrpcServiceBuilder#useBlockingTaskExecutor(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#useBlockingTaskExecutor(boolean)"
      }}>{`type://GrpcServiceBuilder#useBlockingTaskExecutor(boolean)`}</a></h3>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
sb.service(GrpcService.builder()
                      .addService(new MyHelloService())
                      // All service methods will be run within
                      // the blocking executor.
                      .useBlockingTaskExecutor(true) // 👈
                      .build());
`}</code></pre>
    <h2 {...{
      "id": "exception-propagation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#exception-propagation",
        "aria-label": "exception propagation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Exception propagation`}</h2>
    <p>{`It can be very useful to enable `}<a parentName="p" {...{
        "href": "type://Flags#verboseResponses():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Flags.html#verboseResponses()"
      }}>{`type://Flags#verboseResponses()`}</a>{` in your server by specifying the
`}<inlineCode parentName="p">{`-Dcom.linecorp.armeria.verboseResponses=true`}</inlineCode>{` system property, which will automatically return
information about an exception thrown in the server to gRPC clients. Armeria clients will automatically
convert it back into an exception for structured logging, etc. This response will include information about
the actual source code in the server - make sure it is safe to send such potentially sensitive information
to all your clients before enabling this flag!`}</p>
    <p>{`See more details at `}<a parentName="p" {...{
        "href": "/docs/client-grpc"
      }}>{`Client gRPC`}</a>{`.`}</p>
    <h2 {...{
      "id": "server-reflection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#server-reflection",
        "aria-label": "server reflection permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Server Reflection`}</h2>
    <p>{`Armeria supports gRPC server reflection - just add an instance of `}<inlineCode parentName="p">{`ProtoReflectionService`}</inlineCode>{` to your server.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import io.grpc.protobuf.services.ProtoReflectionService;

ServerBuilder sb = Server.builder();
...
sb.service(GrpcService.builder()
                      .addService(new MyHelloService())
                      .addService(ProtoReflectionService.newInstance())
                      .build());
...
Server server = sb.build();
server.start();
`}</code></pre>
    <p>{`For more information, see the official `}<a parentName="p" {...{
        "href": "https://github.com/grpc/grpc-java/blob/master/documentation/server-reflection-tutorial.md"
      }}>{`gRPC Server Reflection tutorial`}</a>{`.`}</p>
    <h2 {...{
      "id": "see-also",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#see-also",
        "aria-label": "see also permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`See also`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/client-grpc"
        }}>{`Client gRPC`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/advanced-kotlin#coroutines-support-for-grpc-kotlin"
        }}>{`Kotlin coroutine gRPC service`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      